import React from 'react'
import { Col, Row } from 'react-bootstrap'

const ImprovementMeasureSkeleton = () => (
  <>
    <div className="d-flex justify-content-start flex-wrap">
      <Row>
        <Col xs={8} sm={4} className="col">
          <p className="skeleton-box skeleton-line-box2" />
        </Col>
        <Col xs={4} sm={2} className="col col-value">
          <p className="skeleton-box skeleton-line-box1" />
        </Col>
        <Col xs={8} sm={4} className="col">
          <p className="skeleton-box skeleton-line-box2" />
        </Col>
        <Col xs={4} sm={2} className="col col-value">
          <p className="skeleton-box skeleton-line-box1" />
        </Col>
      </Row>
      <Row>
        <Col xs={8} sm={4} className="col">
          <p className="skeleton-box skeleton-line-box2" />
        </Col>
        <Col xs={4} sm={2} className="col col-value">
          <p className="skeleton-box skeleton-line-box1" />
        </Col>
        <Col xs={8} sm={4} className="col">
          <p className="skeleton-box skeleton-line-box2" />
        </Col>
        <Col xs={4} sm={2} className="col col-value">
          <p className="skeleton-box skeleton-line-box1" />
        </Col>
      </Row>
      <Row>
        <Col xs={8} sm={4} className="col">
          <p className="skeleton-box skeleton-line-box2" />
        </Col>
        <Col xs={4} sm={2} className="col col-value">
          <p className="skeleton-box skeleton-line-box1" />
        </Col>
        <Col xs={8} sm={4} className="col">
          <p className="skeleton-box skeleton-line-box2" />
        </Col>
        <Col xs={4} sm={2} className="col col-value">
          <p className="skeleton-box skeleton-line-box1" />
        </Col>
      </Row>
      <Row>
        <Col xs={8} sm={4} className="col">
          <p className="skeleton-box skeleton-line-box2" />
        </Col>
        <Col xs={4} sm={2} className="col col-value">
          <p className="skeleton-box skeleton-line-box1" />
        </Col>
        <Col xs={8} sm={4} className="col">
          <p className="skeleton-box skeleton-line-box2" />
        </Col>
        <Col xs={4} sm={2} className="col col-value">
          <p className="skeleton-box skeleton-line-box1" />
        </Col>
      </Row>
      <Row>
        <Col xs={8} sm={4} className="col">
          <p className="skeleton-box skeleton-line-box2" />
        </Col>
        <Col xs={4} sm={2} className="col col-value">
          <p className="skeleton-box skeleton-line-box1" />
        </Col>
        <Col xs={8} sm={4} className="col">
          <p className="skeleton-box skeleton-line-box2" />
        </Col>
        <Col xs={4} sm={2} className="col col-value">
          <p className="skeleton-box skeleton-line-box1" />
        </Col>
      </Row>
    </div>
  </>
)

export default ImprovementMeasureSkeleton
