const MaterialFormStyle = {
  formControl: {
    marginBottom: '1.25em !important',
    minWidth: '100% !important',
  },
  mediumFormControl: {
    marginBottom: '1.25em',
    minWidth: '85%',
  },
  smallFormControl: {
    marginBottom: 0,
  },
  unit: {
    paddingLeft: '5px',
    // color: '#f9f3f2',
    // background: '#87972f'
  },
  valueUnit: {
    minWidth: '270px',
  },
  selectEmpty: {
    marginTop: '1em',
  },
}

export default MaterialFormStyle
