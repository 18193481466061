const ChillerEnergySourceType = [
  {
    id: 1,
    name: 'Hot Water',
  },
  {
    id: 2,
    name: 'Steam',
  },
  {
    id: 3,
    name: 'Exhaust',
  },
  {
    id: 4,
    name: 'Electricity',
  },
]

export default ChillerEnergySourceType
