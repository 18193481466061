const MountingType = [
  {
    id: 1,
    name: 'Free Standing',
  },
  {
    id: 2,
    name: 'Building Integrated',
  },
]

export default MountingType
