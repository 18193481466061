const ExternalWindowType = [
  {
    id: 1,
    name: 'Single Glazed',
  },
  {
    id: 2,
    name: 'Double Glazed (No Low-E Coating)',
  },
  {
    id: 3,
    name: 'Double Glazed (Low-E Coating)',
  },
  {
    id: 4,
    name: 'Triple Glazed (Low-E Coating)',
  },
]

export default ExternalWindowType
