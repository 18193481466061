const PVPanelType = [
  {
    id: 1,
    shortName: 'crystSi',
    name: 'Crystal Silicon',
  },
  {
    id: 2,
    shortName: 'CIS',
    name: 'Copper Indium Selenide',
  },
  {
    id: 3,
    shortName: 'CdTe',
    name: 'Cadmium Telluride',
  },
  {
    id: 4,
    shortName: 'Unknown',
    name: 'Unknown',
  },
]

export default PVPanelType
