export const RefrigerantType = [
  {
    id: 1,
    name: 'R12',
  },
  {
    id: 2,
    name: 'R123',
  },
  {
    id: 3,
    name: 'R134a',
  },
  {
    id: 4,
    name: 'R22',
  },
  {
    id: 5,
    name: 'R290',
  },
  {
    id: 6,
    name: 'R401a',
  },
  {
    id: 7,
    name: 'R404a',
  },
  {
    id: 8,
    name: 'R407a',
  },
  {
    id: 9,
    name: 'R408a',
  },
  {
    id: 10,
    name: 'R409a',
  },
  {
    id: 11,
    name: 'R410a',
  },
  {
    id: 12,
    name: 'R500',
  },
  {
    id: 13,
    name: 'R502',
  },
  {
    id: 14,
    name: 'R507',
  },
  {
    id: 15,
    name: 'R600a',
  },
  {
    id: 16,
    name: 'R744',
  },
  {
    id: 17,
    name: 'R717',
  },
  {
    id: 18,
    name: 'R718',
  },
]

export const AbsorptionChillerRefrigerantType = [
  {
    id: 19,
    name: 'Water-Ammonia',
  },

  {
    id: 20,
    name: 'Water-Lithium Bromide',
  },
]
