const SpaceUsageType = [
  {
    id: 1,
    name: 'Store Room',
  },
  {
    id: 2,
    name: 'Circulation Area',
  },
  {
    id: 3,
    name: 'Toilet',
  },
  {
    id: 4,
    name: 'Reception',
  },
  {
    id: 5,
    name: 'Plant Room',
  },
  {
    id: 6,
    name: 'Office',
  },
  {
    id: 7,
    name: 'Gym',
  },
  {
    id: 8,
    name: 'Changing Room with Showers',
  },
  {
    id: 9,
    name: 'Food Preparation Area',
  },
  {
    id: 10,
    name: 'Eating/Drinking Area',
  },
  {
    id: 11,
    name: 'Workshop - small scale',
  },
  {
    id: 12,
    name: 'Car Park',
  },
]

export default SpaceUsageType
