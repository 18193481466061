import axios from 'axios'

export const getLightingSystemByBuildingId = async (buildingId, idToken) => {
  let result
  await axios({
    method: 'get',
    url: process.env.REACT_APP_BACKEND_API + `/lighting-system/findByBuildingId/${buildingId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
    //data: data,
    body: {},
  })
    .then((response) => {
      //console.log('getLightingSystemByBuildingId: ', response.data)
      result = response.data
      //result = 'Retrieve successfully!'
      //return response.data
    })
    .catch((error) => {
      if (error.response) {
        // setErrorMsg(error.response.data.message)
        result = error.response.data.message
      }
    })

  return result
}
