const RoofType = [
  {
    id: 1,
    name: 'Pitched Roof',
  },
  {
    id: 2,
    name: 'Flat Roof',
  },
  {
    id: 3,
    name: 'Other',
  },
]

export default RoofType
