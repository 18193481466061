export const subSystemPerformanceDataEN = {
  data: [
    {
      name: 'Energy Usage Intensity',
      'Minimum Requirement': 46,
      'Current Performance': 53,
      'Potential Best In Class': 76,
    },
    {
      name: 'Cooling Efficiency',
      'Minimum Requirement': 30,
      'Current Performance': 28,
      'Potential Best In Class': 70,
    },
    {
      name: 'Heating Efficiency',
      'Minimum Requirement': 40,
      'Current Performance': 53,
      'Potential Best In Class': 76,
    },
    {
      name: 'Lighting Efficiency',
      'Minimum Requirement': 40,
      'Current Performance': 38,
      'Potential Best In Class': 76,
    },
    {
      name: 'Mechanical Ventilation Efficiency',
      'Minimum Requirement': 49,
      'Current Performance': 51,
      'Potential Best In Class': 68,
    },
    {
      name: 'Envelope Performance',
      'Minimum Requirement': 20,
      'Current Performance': 72,
      'Potential Best In Class': 76,
    },
    {
      name: 'Renewables Usage',
      'Minimum Requirement': 19,
      'Current Performance': 53,
      'Potential Best In Class': 64,
    },
  ],
  keys: ['Potential Best In Class', 'Current Performance', 'Minimum Requirement'],
  indexBy: ['name'],
}

export const subSystemPerformanceDataDE = {
  data: [
    {
      name: 'Elektr. Energiebedarf',
      'Gesetzl. Mindest-anforderungen': 46,
      Aktuell: 53,
      Optimal: 76,
    },
    {
      name: 'Kühllast',
      'Gesetzl. Mindest-anforderungen': 30,
      Aktuell: 28,
      Optimal: 70,
    },
    {
      name: 'Heizlast',
      'Gesetzl. Mindest-anforderungen': 40,
      Aktuell: 53,
      Optimal: 76,
    },
    {
      name: 'Beleuchtungs-effizienz',
      'Gesetzl. Mindest-anforderungen': 40,
      Aktuell: 38,
      Optimal: 76,
    },
    {
      name: 'Effizienz der Lüftungsanlagen',
      'Gesetzl. Mindest-anforderungen': 49,
      Aktuell: 51,
      Optimal: 68,
    },
    {
      name: 'Isolierung',
      'Gesetzl. Mindest-anforderungen': 20,
      Aktuell: 72,
      Optimal: 76,
    },
    {
      name: 'Einsatz erneuerbarer Energien',
      'Gesetzl. Mindest-anforderungen': 19,
      Aktuell: 53,
      Optimal: 64,
    },
  ],
  keys: ['Aktuell', 'Optimal', 'Gesetzl. Mindest-anforderungen'],
  indexBy: ['name'],
}
